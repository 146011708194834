import { createFeature, createReducer, on } from '@ngrx/store';
import { ImporterMissingGroupActions } from './import-missing-group.actions';
import { ImporterMissingGroup } from 'src/app/shared/models';

export const importerMissingGroupFeatureKey = 'import-missing-group';

export interface ImporterMissingGroupState {
  importerMissingGroups: ImporterMissingGroup[];
  importerMissingGroup: ImporterMissingGroup | null;
  loading: boolean;
  error: any;
}

export const initialState: ImporterMissingGroupState = {
  importerMissingGroups: [],
  importerMissingGroup: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ImporterMissingGroupActions.loadImporterMissingGroups,
    (state): ImporterMissingGroupState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.loadImporterMissingGroupsSuccess,
    (state, { importerMissingGroups }): ImporterMissingGroupState => ({
      ...state,
      importerMissingGroups: [
        ...state.importerMissingGroups.filter(
          x =>
            !importerMissingGroups.find(
              y => x.user?.id && x.user?.id === y.user?.id && x.id === y.id
            )
        ),
        ...importerMissingGroups,
      ],
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.loadImporterMissingGroupsFailure,
    (state, { error }): ImporterMissingGroupState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterMissingGroupActions.loadImporterMissingGroup,
    (state): ImporterMissingGroupState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.loadImporterMissingGroupSuccess,
    (state, { importerMissingGroup }): ImporterMissingGroupState => ({
      ...state,
      importerMissingGroups: [
        ...state.importerMissingGroups.filter(
          item =>
            importerMissingGroup.id !== item.id &&
            importerMissingGroup.user?.id !== item.user?.id
        ),
        importerMissingGroup,
      ],
      importerMissingGroup: importerMissingGroup,
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.loadImporterMissingGroupFailure,
    (state, { error }): ImporterMissingGroupState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterMissingGroupActions.addImporterMissingGroup,
    (state): ImporterMissingGroupState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.addImporterMissingGroupSuccess,
    (state, { importerMissingGroup }): ImporterMissingGroupState => ({
      ...state,

      importerMissingGroups: [
        ...state.importerMissingGroups,
        importerMissingGroup,
      ],

      loading: false,

      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.addImporterMissingGroupFailure,
    (state, { error }): ImporterMissingGroupState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterMissingGroupActions.removeImporterMissingGroup,
    (state): ImporterMissingGroupState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.removeImporterMissingGroupSuccess,
    (state, { importerMissingGroupId }): ImporterMissingGroupState => ({
      ...state,
      importerMissingGroups: state.importerMissingGroups.filter(
        importerMissingGroup =>
          importerMissingGroup.id !== importerMissingGroupId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.removeImporterMissingGroupFailure,
    (state, { error }): ImporterMissingGroupState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterMissingGroupActions.updateImporterMissingGroup,
    (state): ImporterMissingGroupState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.updateImporterMissingGroupSuccess,
    (state, { importerMissingGroup }): ImporterMissingGroupState => ({
      ...state,
      importerMissingGroups: state.importerMissingGroups.map(item =>
        // item.id === importerMissingGroup.id ? importerMissingGroup : item
        item.id === importerMissingGroup.id
          ? { ...item, ...importerMissingGroup }
          : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterMissingGroupActions.updateImporterMissingGroupFailure,
    (state, { error }): ImporterMissingGroupState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const importerMissingGroupFeature = createFeature({
  name: importerMissingGroupFeatureKey,
  reducer,
});
