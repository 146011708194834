import { createActionGroup, props } from '@ngrx/store';
import { ImporterMissingGroup, Type } from 'src/app/shared/models';

export const ImporterMissingGroupActions = createActionGroup({
  source: 'ImporterMissingGroup',
  events: {
    'Load ImporterMissingGroups': props<{
      from?: any;
      userId: string;
      size?: any;
      term?: string;
      importerMissingGroupType?: Type;
    }>(),
    'Load ImporterMissingGroups Success': props<{
      importerMissingGroups: ImporterMissingGroup[];
    }>(),
    'Load ImporterMissingGroups Failure': props<{ error: any }>(),
    'Load ImporterMissingGroup': props<{
      importerMissingGroupId: string;
      userId: string;
    }>(),
    'Load ImporterMissingGroup Success': props<{
      importerMissingGroup: ImporterMissingGroup;
    }>(),
    'Load ImporterMissingGroup Failure': props<{ error: any }>(),
    'Add ImporterMissingGroup': props<{
      userId: string;
      importerMissingGroup:
        | ImporterMissingGroup
        | Partial<ImporterMissingGroup>;
    }>(),
    'Add ImporterMissingGroup Success': props<{
      importerMissingGroup: ImporterMissingGroup;
    }>(),
    'Add ImporterMissingGroup Failure': props<{ error: any }>(),
    'Remove ImporterMissingGroup': props<{
      importerMissingGroupId: string;
      userId: string;
    }>(),
    'Remove ImporterMissingGroup Success': props<{
      importerMissingGroupId: string;
    }>(),
    'Remove ImporterMissingGroup Failure': props<{ error: any }>(),
    'Update ImporterMissingGroup': props<{
      userId: string;
      importerMissingGroupId: string;
      importerMissingGroup: Partial<ImporterMissingGroup>;
    }>(),
    'Update ImporterMissingGroup Success': props<{
      userId: string;
      importerMissingGroupId: string;
      importerMissingGroup: Partial<ImporterMissingGroup>;
    }>(),
    'Update ImporterMissingGroup Failure': props<{ error: any }>(),
  },
});
